import { AxiosError } from 'axios'
import format from 'date-fns/format'
import lodash from 'lodash'
import { toast } from 'react-toastify'

import { ItemProps } from '../api/interfaces/IFormSchema'
import { postForm } from '../api/services/formsService'

const processData = (
  data: ItemProps,
  arrayFieldsSet: Set<string>,
  dateFieldsSet: Set<string>
): ItemProps => {
  for (const field in data) {
    if (arrayFieldsSet.has(field))
      // convert array fields to array from string as expected by the form submission API endpoint
      data[field] = [data[field]]

    if (dateFieldsSet.has(field))
      // For sending the date to API and database, we use the ISO standard YYYY-MM-DD, that goes from
      // bigger period to smaller period so as to not add code to guess is if the date is in US or EU style
      data[field] = format(new Date(data[field]), 'yyyy-MM-dd')

    if (Array.isArray(data[field]))
      if (data[field].length === 0) delete data[field]
  }
  return data
}

export const processAndPostData = async (
  id: string,
  data: ItemProps,
  arrayFieldsSet: Set<string>,
  dateFieldsSet: Set<string>,
  showToast: boolean,
  successMessage: string,
  hasAuthentication?: boolean
): Promise<boolean> => {
  // lodash.pickBy will remove the fields that do no have any/blank values
  const processedData = processData(
    lodash.pickBy(data),
    arrayFieldsSet,
    dateFieldsSet
  )
  if (id)
    try {
      const response = await postForm(
        parseInt(id),
        processedData,
        hasAuthentication
      )
      if (response) {
        if (showToast) toast.success(successMessage)
        return true
      }
    } catch (err) {
      toast.error(`Error while submitting form: ${(err as AxiosError).message}`)
    }
  return false
}
