import './SkipLink.scss'

interface SkipLinkProperties {
  title?: string
  skipTo?: string
}

const SkipLink: React.FC<SkipLinkProperties> = ({ title, skipTo }) => (
  <a className="sr-only sr-only-focusable" href={skipTo}>
    {title}
  </a>
)

SkipLink.defaultProps = {
  title: 'Skip to main content',
  skipTo: '#main',
}

export default SkipLink
