const languageCodeMapper = (languageName: string): string => {
  switch (languageName.toLowerCase()) {
    case 'english':
      return 'en'
    case 'gaeilge':
      return 'ga'
    default:
      return 'en'
  }
}

export { languageCodeMapper }
