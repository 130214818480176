import {
  Form as FormStorybook,
  Button,
  RenderFormFields,
} from '@ogcio/storybook-react'
import { AxiosError } from 'axios'
import Cookies from 'js-cookie'
import jwt_decode from 'jwt-decode'
import { useStateMachine } from 'little-state-machine'
import lodash from 'lodash'
import { FC, useEffect, useState } from 'react'
import { FieldValues, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import IForm from '../../api/interfaces/IForm'
import IFormSchema, { IPage, ItemProps } from '../../api/interfaces/IFormSchema'
import IKeyValuePair from '../../api/interfaces/IKeyValuePair'
import IURLParams from '../../api/interfaces/IURLParams'
import {
  getFormSchema,
  getFormTranslations,
} from '../../api/services/formsService'
import MultiPageFormPostResponse from '../../components/shared/MultiPageFormPostResponse'
import { updateAction } from '../../utils/littleStateMachineActions'
import { processAndPostData } from '../../utils/postData'

import './Form.scss'

const { REACT_APP_EFORMSAPI_MYGOVID_AUTH_URL } = process.env

const dateFieldsSet = new Set<string>()
const arrayFieldsSet = new Set<string>()

const Form: FC<IForm> = ({ setFormId }) => {
  const { id, languageCode } = useParams<IURLParams>()
  const defaultLanguageCode = 'en'
  const [formSchema, setFormSchema] = useState<IFormSchema>()
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [isFormMultiPage, setIsFormMultiPage] = useState(false)
  const [formHasAuthentication, setFormHasAuthentication] = useState(false)
  const [totalPages, setTotalPages] = useState(0)
  const [pageNo, setPageNo] = useState(0)
  const { actions, state } = useStateMachine({ updateAction })
  const [isMultiPageFormLastPage, setIsMultiPageFormLastPage] = useState(false)
  const [myGovIdCookie, setMyGovIdCookie] = useState<string>()
  const [initialFormValues, setInitialFormValues] = useState<ItemProps>()
  const [submissionCount, setSubmissionCount] = useState(0)
  const [formMethods, setFormMethods] =
    useState<UseFormReturn<FieldValues, IKeyValuePair>>()

  useEffect(() => {
    const govIdCookie = Cookies.get('mygovid_token')
    if (govIdCookie && formHasAuthentication) {
      setMyGovIdCookie(govIdCookie)
      const decodedToken: ItemProps = jwt_decode(govIdCookie)
      const { givenName, surname, mobile, email } = decodedToken
      setInitialFormValues({
        firstName: givenName,
        surname,
        phoneNumber: mobile,
        emailAddress: email,
      })
    }
  }, [formHasAuthentication])

  useEffect(() => {
    if (id) {
      setFormId(id)
    }
    if (languageCode) navigate(`/${languageCode}/forms/${id}`)
    if (!languageCode) navigate(`/${defaultLanguageCode}/forms/${id}`)
  }, [id, setFormId, navigate, languageCode])

  useEffect(() => {
    const fetchTranslationSchema = async () => {
      if (languageCode === 'en') {
        return
      }

      if (id)
        try {
          const langCode = languageCode ? languageCode : defaultLanguageCode
          const response = await getFormTranslations(parseInt(id), langCode)

          return response.translation_schema
        } catch (err) {
          toast.error(
            `Error while fetching translations: ${(err as AxiosError).message}`
          )
        }
    }

    const fetchFormSchema = async () => {
      if (id)
        try {
          const [schema, translationSchema] = await Promise.all([
            getFormSchema(parseInt(id)),
            fetchTranslationSchema(),
          ])

          const isTranslation = translationSchema !== undefined

          const isMultiPageForm =
            schema?.ui_schema.fieldLayoutType === 'multi-page'

          if (isMultiPageForm) {
            const pages = schema?.ui_schema.pages
            const currentPage = (pages as IPage[])[pageNo]
            setIsFormMultiPage(true)
            setFormHasAuthentication(
              schema?.ui_schema?.authentication === 'mygovid'
            )
            setTotalPages(pages.length)
            setFormSchema({
              ...schema,
              json_schema: {
                ...schema.json_schema,
                properties: lodash.pick(schema?.json_schema?.properties, [
                  ...currentPage.fields,
                ]),
              },
            })
            setTitle(currentPage.title)
            setDescription(currentPage.description)
          } else if (isTranslation) {
            setFormSchema({
              ...schema,
              json_schema: {
                ...schema.json_schema,
                properties: translationSchema?.properties,
              },
              ui_schema: {
                ...schema.ui_schema,
                success_page_message:
                  translationSchema?.ui_schema?.success_page_message ??
                  schema.ui_schema?.success_page_message,
              },
            })
            setTitle(translationSchema?.title)
            setDescription(translationSchema?.description)
          } else {
            setFormSchema(schema)
            setTitle(schema?.json_schema?.title)
            setDescription(schema?.json_schema?.description)
          }

          // Following is to translate the static text on the page
          i18n.changeLanguage(languageCode)
        } catch (err) {
          toast.error(
            `Error while fetching form schema: ${(err as AxiosError).message}`
          )
        }
    }

    fetchFormSchema()
  }, [id, languageCode, i18n, pageNo])

  const handleBackButtonClick = () => {
    if (pageNo !== 0) {
      setPageNo((page) => page - 1)
      const currentPageValues = formMethods?.getValues()
      actions.updateAction(currentPageValues)
      setInitialFormValues(state)
    }
  }

  const submitForm = async (data: ItemProps) => {
    if (id) {
      if (!isFormMultiPage) {
        processAndPostData(
          id,
          data,
          arrayFieldsSet,
          dateFieldsSet,
          true,
          t('successToastMessage')
        )
        setSubmissionCount((count) => count + 1)
      } else {
        actions.updateAction(data)
        setInitialFormValues(state)
        if (pageNo < totalPages - 1) {
          setPageNo((page) => page + 1)
          setIsMultiPageFormLastPage(false)
        } else {
          setIsMultiPageFormLastPage(true)
          setSubmissionCount((count) => count + 1)
        }
      }
    }
  }

  const loginUsingMyGovId = () => {
    window.location.replace(
      `${REACT_APP_EFORMSAPI_MYGOVID_AUTH_URL}mygovid/login?redirect_to=${window.location.href}`
    )
  }

  const renderForm = () => {
    if (formSchema) {
      const uiSchema = formSchema?.ui_schema

      if (!uiSchema || !formSchema?.json_schema?.properties)
        return (
          <span className="text-danger">
            An error occurred while fetching the form
          </span>
        )

      if (uiSchema?.authentication === 'mygovid' && !myGovIdCookie) {
        loginUsingMyGovId()
        return
      }

      return (
        <>
          <h1 className="font-weight-light">{title}</h1>
          <p>{description}</p>
          <p className="text-danger" aria-hidden>
            {t('requiredFields')}
          </p>

          <FormStorybook
            /* temp fix for clearing form controlled inputs (proper fix should be done in the component library */
            key={submissionCount}
            jsonSchema={formSchema?.json_schema}
            handleFormSubmit={(data) => submitForm(data)}
            i18nInstance={i18n}
            handleFormMethods={(methods) => setFormMethods(methods)}
          >
            <RenderFormFields
              arrayFieldsSet={arrayFieldsSet}
              dateFieldsSet={dateFieldsSet}
              formSchema={formSchema}
              pageNo={pageNo}
              initialFormValues={initialFormValues}
            ></RenderFormFields>
            {isFormMultiPage && pageNo !== 0 && (
              <span>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={handleBackButtonClick}
                >
                  {t('back')}
                </button>
                &nbsp;&nbsp;&nbsp;
              </span>
            )}
            <Button
              id="submit"
              title={
                isFormMultiPage
                  ? pageNo < totalPages - 1
                    ? t('proceed')
                    : t('submit')
                  : t('submit')
              }
            />
          </FormStorybook>
        </>
      )
    }
  }

  const successMessage =
    (formSchema?.ui_schema?.success_page_message as string) ??
    t('successPageMessage')

  const failureMessage =
    (formSchema?.ui_schema?.failure_page_message as string) ??
    t('failurePageMessage')

  return (
    <div id="main" className="mt-1 p-1">
      {id && isMultiPageFormLastPage ? (
        <MultiPageFormPostResponse
          id={id}
          successMessage={successMessage}
          failureMessage={failureMessage}
          arrayFieldsSet={arrayFieldsSet}
          dateFieldsSet={dateFieldsSet}
        />
      ) : (
        renderForm()
      )}
    </div>
  )
}

export default Form
