import ReactDOM from 'react-dom'
import './index.scss'
import './styles/layout.scss'
import './styles/bootstrap-override.scss'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import './i18n'
import { Suspense } from 'react'

ReactDOM.render(
  <BrowserRouter>
    {/* As the translations are loaded asynchronously, wrapping the app with a
    Suspense component is required to prevent "Uncaught Error: App suspended
    while rendering, but no fallback UI was specified." error */}
    <Suspense fallback="Loading...">
      <App />{' '}
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
)
