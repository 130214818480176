import { useStateMachine } from 'little-state-machine'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { clearAction } from '../../utils/littleStateMachineActions'
import { processAndPostData } from '../../utils/postData'

interface IMultiPageFormPostResponseProps {
  id: string
  arrayFieldsSet: Set<string>
  dateFieldsSet: Set<string>
  successMessage: string
  failureMessage: string
}

export const MultiPageFormPostResponse: FC<IMultiPageFormPostResponseProps> = ({
  id,
  arrayFieldsSet,
  dateFieldsSet,
  successMessage,
  failureMessage,
}) => {
  const { t } = useTranslation()
  const { state, actions } = useStateMachine({ clearAction })
  const [hasFormPostedSuccessfully, setHasFormPostedSuccessfully] =
    useState<boolean>()

  useEffect(() => {
    const postData = async () => {
      const response = await processAndPostData(
        id,
        state,
        arrayFieldsSet,
        dateFieldsSet,
        false,
        t('successToastMessage'),
        true
      )

      if (response) {
        setHasFormPostedSuccessfully(true)
        actions.clearAction()
      } else setHasFormPostedSuccessfully(false)

      return response
    }

    postData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderHTML = (isSuccess: boolean) => {
    if (isSuccess)
      return (
        <div>
          <h1 className="font-weight-light">{t('success')}</h1>
          <p>{successMessage}</p>
        </div>
      )

    return (
      <div>
        <h1 className="font-weight-light">{t('failure')}</h1>
        <p className="text-danger">{failureMessage}</p>
      </div>
    )
  }

  return (
    <>
      {hasFormPostedSuccessfully ? (
        renderHTML(true)
      ) : hasFormPostedSuccessfully === false ? (
        renderHTML(false)
      ) : (
        <></>
      )}
    </>
  )
}

export default MultiPageFormPostResponse
