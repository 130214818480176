import { FC, useEffect } from 'react'

interface RedirectProps {
  url: string
}

const Redirect: FC<RedirectProps> = ({ url }) => {
  useEffect(() => {
    window.location.replace(url)
  }, [url])

  return <div>Redirecting...</div>
}

export default Redirect
