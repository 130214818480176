import axios from 'axios'
import jwtDecode from 'jwt-decode'
import IJWTToken from '../interfaces/IJWTToken'

const localStorageAPIToken = localStorage.getItem('api:token')!

const authenticateAPI = async (): Promise<string> => {
  const {
    REACT_APP_EFORMSAPI_AUTH_URL,
    REACT_APP_EFORMSAPI_CLIENT_ID,
    REACT_APP_EFORMSAPI_CLIENT_SECRET,
  } = process.env
  try {
    const response = await axios.post(
      REACT_APP_EFORMSAPI_AUTH_URL!,
      {
        data: {
          grant_type: 'client_credentials',
          scope: 'public',
        },
      },
      {
        auth: {
          username: REACT_APP_EFORMSAPI_CLIENT_ID!,
          password: REACT_APP_EFORMSAPI_CLIENT_SECRET!,
        },
      }
    )
    const accessToken = response.data.access_token
    localStorage.setItem('api:token', accessToken)
    return accessToken
  } catch (error) {
    // TODO Replace with a logging library post outcome of task https://dev.azure.com/OGCIO-Digital-Services/Digital%20Services%20Programme/_workitems/edit/185
    console.error(`authenticateAPI: ${error}`)
    throw error
  }
}

const isAPIAuthenticated = (): boolean => {
  if (!localStorageAPIToken) return false

  const { exp } = jwtDecode<IJWTToken>(localStorageAPIToken!)
  // Check if token is already expired and set expiration time a minute early to avoid latency issues
  const expirationTime = exp * 1000 - 60000
  return Date.now() <= expirationTime
}

const getAccessToken = async (): Promise<string> => {
  return isAPIAuthenticated() ? localStorageAPIToken : authenticateAPI()
}

export { getAccessToken }
