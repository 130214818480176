import './App.scss'
import 'react-toastify/dist/ReactToastify.css'
import { NavbarOGCIO } from '@ogcio/storybook-react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify'
import Form from './domain/Form/Form'
import Redirect from './components/Redirect/Redirect'
import { FC, useState } from 'react'
import { languageCodeMapper } from './utils/languagesUtil'
import PageNotFound from './components/page-not-found/PageNotFound'
import SkipLink from './components/skip-link/SkipLink'
import { createStore, StateMachineProvider } from 'little-state-machine'

createStore({})

const App: FC = () => {
  const [formId, setFormId] = useState<string>()
  const navigate = useNavigate()

  return (
    <StateMachineProvider>
      <Container fluid>
        <SkipLink />
        <ToastContainer
          position="top-right"
          autoClose={8000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <NavbarOGCIO
          variant="eforms"
          handleLanguageChange={(language: string) => {
            const languageCode = languageCodeMapper(language)
            navigate(`/${languageCode}/forms/${formId}`)
          }}
        />
        <Routes>
          <Route
            path="/"
            element={<Redirect url="https://www.gov.ie/" />}
          ></Route>
          <Route
            path="/forms"
            element={<Redirect url="https://www.gov.ie/" />}
          ></Route>
          <Route
            path=":languageCode/forms/:id"
            element={<Form setFormId={setFormId} />}
          ></Route>
          <Route
            path="/forms/:id"
            element={<Form setFormId={setFormId} />}
          ></Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Container>
    </StateMachineProvider>
  )
}

export default App
