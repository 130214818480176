import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import FormsList from '../interfaces/IFormsList'
import { getAccessToken } from './authService'
import FormSchemaProps, {
  IFormTranslationsProps,
  ItemProps,
} from '../interfaces/IFormSchema'
import FormSubmissionResponse from '../interfaces/IFormSubmissionResponse'
const { REACT_APP_EFORMSAPI_BASE_URL } = process.env

const getForms = async (): Promise<FormsList[]> => {
  try {
    const accessToken = await getAccessToken()

    const response = await axios.get<FormsList[]>(
      REACT_APP_EFORMSAPI_BASE_URL!,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )

    return response.data
  } catch (err) {
    // TODO Replace with a logging library post outcome of task https://dev.azure.com/OGCIO-Digital-Services/Digital%20Services%20Programme/_workitems/edit/185
    const error = err as AxiosError
    throw error
  }
}

const getFormSchema = async (id: number): Promise<FormSchemaProps> => {
  try {
    const response = await axios.get<FormSchemaProps>(
      `${REACT_APP_EFORMSAPI_BASE_URL}/${id}/schemas/`
    )

    return response.data
  } catch (err) {
    // TODO Replace with a logging library post outcome of task https://dev.azure.com/OGCIO-Digital-Services/Digital%20Services%20Programme/_workitems/edit/185
    const error = err as AxiosError
    throw error
  }
}

const getFormTranslations = async (
  id: number,
  languageCode: string
): Promise<IFormTranslationsProps> => {
  try {
    const response = await axios.get<IFormTranslationsProps>(
      `${REACT_APP_EFORMSAPI_BASE_URL}/${id}/translations/${languageCode}/`
    )

    return response.data
  } catch (err) {
    // TODO Replace with a logging library post outcome of task https://dev.azure.com/OGCIO-Digital-Services/Digital%20Services%20Programme/_workitems/edit/185
    const error = err as AxiosError
    throw error
  }
}

const postForm = async (
  id: number,
  data: ItemProps,
  hasAuthentication = false
): Promise<any> => {
  const config: AxiosRequestConfig<any> = hasAuthentication
    ? {
        headers: {
          Accept: 'application/json',
          Cookie: document.cookie,
        },
        withCredentials: true,
      }
    : {
        headers: {
          Accept: 'application/json',
        },
      }

  try {
    const response = await axios.post<FormSubmissionResponse>(
      `${REACT_APP_EFORMSAPI_BASE_URL}/${id}/submissions/`,
      { data: data },
      config
    )

    return response.data
  } catch (err) {
    const error = err as AxiosError
    throw error
  }
}

export { getForms, getFormSchema, getFormTranslations, postForm }
