import { GlobalState } from 'little-state-machine'
import { ItemProps } from '../api/interfaces/IFormSchema'

const clearAction = (): ItemProps => {
  return {}
}

const updateAction = (state: GlobalState, payload: ItemProps): ItemProps => {
  return {
    ...state,
    ...payload,
  }
}

export { clearAction, updateAction }
